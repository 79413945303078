:root {
    --section-pt: 150px;
    --container-gap: 40px;
}

main {
    display: block; //IE9-11 Support
}

.section {
    padding-block: var(--section-pt);

    &-pt-0 {
        padding-block-start: 0;
    }

    &-0 {
        padding-block: 0;
    }

    @include media-breakpoint-down(md) {
        --section-pt: 100px;
    }

    @include media-breakpoint-down(sm) {
        --section-pt: 50px;
    }

    &--first {
        @include media-breakpoint-down(md) {
            --section-pt: 150px;
        }

        @include media-breakpoint-down(sm) {
            --section-pt: 150px;
        }
    }

    &[data-bg="dark"] {
        background-color: var(--bg-dark);
    }

    &[data-bg="sdg3"] {
        background-color: var(--color-sdg3);
    }

    &[data-bg="sdg7"] {
        background-color: var(--color-sdg7);
    }

    &[data-bg="sdg8"] {
        background-color: var(--color-sdg8);
    }

    &[data-bg="sdg9"] {
        background-color: var(--color-sdg9);
    }

    &[data-bg="sdg13"] {
        background-color: var(--color-sdg13);
    }

    &[data-bg="sdg17"] {
        background-color: var(--color-sdg17);
    }

    &[data-bg="light"] {
        background-color: var(--bg-light);
    }

    &[data-size="min"] {
        min-height: 100vh;
    }

    &[data-section="small"] {
        --section-pt: 50px;
    }

    &[data-section="story"] {
        position: relative;
        padding-block: inherit;
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        .box {
            align-self: center;
            position: relative;
            margin-block-start: -100vh;
            margin-inline: auto;
            padding-inline: 5rem;
            padding-block: 25vh;
            width: 100%;
            max-width: 800px;
            z-index: 10;

            &[data-position="bottom"] {
                align-self: flex-end;
                padding-block: 0 3rem;
            }

            &--right {
                grid-column: 2/-1;
            }

            @include media-breakpoint-down(xl) {
                padding-inline: inherit;
                grid-column: span 2;
            }
        }

        .image {
            grid-column: 1 / -1;
            position: sticky;
            top: 0;
            height: 100vh;
            z-index: 5;
            overflow: hidden;
            //&::after {
            //	content: "";
            //	position: absolute;
            //	inset: 0;
            //	background-color: rgba(black, 0.75);
            //}

            img {
                object-fit: cover;
                object-position: center;
                height: 100%;
                width: 100%;
            }
        }
    }

    &[data-section="full"] {
        position: relative;
        display: grid;
        padding-block: initial;
        min-height: 100vh;

        @include media-breakpoint-down(md) {
            min-height: initial;
        }

        .image {
            position: absolute;
            inset: 0;
            overflow: hidden;
            z-index: 0;

            @include media-breakpoint-down(md) {
                position: relative;
                inset: inherit;
                aspect-ratio: 5/3;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }

        .box {
            align-self: center;
            padding-block: 100px;
            width: 100%;
            z-index: 10;

            @include media-breakpoint-down(md) {
                padding-block: 0;
            }

            &[data-position="start"] {
                align-self: flex-start;
            }

            &[data-position="end"] {
                align-self: flex-end;
            }
        }

        @include media-breakpoint-down(md) {
            .container {
                padding-right: 0;
                padding-left: 0;
            }
        }

        .content-box {
            padding: 3rem;
            background-color: white;

            @include media-breakpoint-down(md) {
                padding-inline: 0;
            }

            &[data-bg="dark"] {
                background-color: var(--bg-dark);
            }

            &[data-bg="light"] {
                background-color: var(--bg-light);
            }

            @include media-breakpoint-down(md) {
                padding-right: var(--container-gap);
                padding-left: var(--container-gap);
            }
        }
    }

    &[data-section="detail"] {
        position: relative;
        padding-block-start: inherit;

        .image {
            position: relative;
            margin-inline: auto;
            // max-width: 1920px;
            aspect-ratio: 2/1;

            &::after {
                content: "";
                position: absolute;
                inset: auto 0 0;
                width: 100vw;
                height: clamp(50px, 15vw, 150px);
                // background-color: rgb(38, 50, 56);
                background: linear-gradient(180deg,
                        rgba(var(--bg-gradient-rgb), 0.9) 0%,
                        rgba(var(--bg-gradient-rgb), 1) 90%);
            }

            img {
                object-fit: cover;
                object-position: center top;
                height: 100%;
                width: 100%;
            }
        }
    }

    &[data-section="half"] {
        position: relative;
        padding-block: inherit;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        min-height: 100vh;

        @include media-breakpoint-down(xl) {
            min-height: 75vh;
        }

        @include media-breakpoint-down(md) {
            grid-template-columns: repeat(1, 1fr);
            min-height: inherit;
        }

        .image {
            position: relative;
            width: 100%;
            height: 100%;
            overflow: hidden;

            @include media-breakpoint-down(md) {
                width: 100%;
                aspect-ratio: 1/1;
                order: 1;
            }

            img {
                object-fit: cover;
                object-position: center top;
                height: 100%;
                width: 100%;
            }
        }

        .box {
            margin-inline: auto;
            padding-block: 4rem;
            max-width: 640px;
            align-self: center;

            @include media-breakpoint-down(md) {
                order: 2;
                padding-block: 2rem 4rem;
            }
        }
    }
}

.section-item {
    &:not(:last-child) {
        margin-bottom: 6rem;

        @include media-breakpoint-down(md) {
            margin-bottom: 4rem;
        }

        @include media-breakpoint-down(sm) {
            margin-bottom: 2rem;
        }
    }
}

.container-big {
    margin-inline: auto;
    max-width: 1680px;
}

.container,
.container-big,
.container-fluid {
    padding-right: var(--container-gap);
    padding-left: var(--container-gap);

    @include media-breakpoint-down(sm) {
        --container-gap: 20px;
    }
}

.content-box {
    padding: 3rem;
    background-color: white;
}

.content {
    &.highlighted-content {
        padding: 50px;
        background-color: var(--color-tertiary-4);

        /*box-shadow: 25px 25px;*/
        @include media-breakpoint-down(sm) {
            padding: 25px;
        }

        h3 {
            color: var(--color-secondary);
            border-top: 10px var(--color-secondary) solid;
            display: inline-block;
            padding-block: 15px 15px;
        }
    }

    ul,
    ol {
        margin-block-end: 2rem;

        li {
            position: relative;
            margin-block-end: 1rem;
            padding-inline-start: 2rem;

            &::before {
                color: var(--color-primary);
            }
        }
    }

    ul {
        li {
            &::before {
                content: "•";
                position: absolute;
                top: 0;
                left: .5rem;
            }
        }
    }


    .list-vr {
        li {
            &::before {
                content: "";
                position: absolute;
                top: .35em;
                left: 0;
                width: 1em;
                height: 1em;
                background-color: black;
                mask-image: url('../img/icon/icon-vr.svg');
                mask-repeat: no-repeat;
                mask-position: center;
                mask-size: contain;
            }
        }

        &[data-list="sdg3"] {
            li {
                &::before {
                    background-color: var(--color-sdg3);
                }
            }
        }

        &[data-list="sdg9"] {
            li {
                &::before {
                    background-color: var(--color-sdg9);
                }
            }
        }

        &[data-list="sdg17"] {
            li {
                &::before {
                    background-color: var(--color-sdg17);
                }
            }
        }
    }

    .list-plus {
        li {
            &::before {
                content: "+";
                position: absolute;
                top: 0;
                left: .5rem;
            }
        }
    }


    ol {
        li {
            counter-increment: section;

            &::before {
                content: counter(section) ".";
                position: absolute;
                top: 0;
                left: 0;
            }
        }
    }

    a {
        &:not(.btn) {
            color: var(--color-primary);
            text-decoration: underline;
        }
    }

    &>h2,
    &>h3,
    &>h4,
    &>h5 {
        &:not(:first-child) {
            margin-block-start: 3rem;
        }
    }

    &[data-content="white"] {

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            --title: white;
        }

        --text: white;
    }

    &[data-content="invert"] {

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            --title: white;
        }

        .title-info,
        .title-cat {
            --text: white;
        }

        --text: white;
    }

    &[data-content="white"],
    &[data-content="invert"] {
        .title-primary {
            @extend .title-primary;
        }

        .title-secondary {
            @extend .title-secondary;
        }

        a {
            &:not(.btn) {
                color: var(--color-primary-light);
            }
        }


        .links-navigation {
            list-style: none;

            li {
                &::before {
                    content: "";
                    position: absolute;
                    top: .25em;
                    left: 0;
                    width: 1em;
                    height: 1em;
                    border-radius: 50vw;
                    background-color: var(--color-tertiary);
                    mask-image: url("../img/icon/ic_pfeil-rechts_24.svg");
                    mask-position: center;
                    mask-size: cover;
                }
            }

            a {
                position: relative;
                display: inline-block;
                color: var(--color-tertiary);
                text-decoration: none;
                @include effect();

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}

.content-item {
    &:not(:last-child) {
        margin-block-end: 3rem;
    }
}

.col-md-content {
    @include media-breakpoint-down(md) {
        margin-block-end: 2rem;
    }
}

.col-md-order {
    @include media-breakpoint-down(md) {
        order: -1;
    }
}

.col-lg-content {
    @include media-breakpoint-down(lg) {
        margin-block-end: 2rem;
    }
}

.col-xl-content {
    @include media-breakpoint-down(xl) {
        margin-block-end: 2rem;
    }
}

.col-xl-order {
    @include media-breakpoint-down(xl) {
        order: -1;
    }
}

.infobox {
    position: relative;
    display: flex;
    padding: 2rem;
    background-color: var(--bg-light);

    &::before {
        flex-shrink: 0;
        content: "";
        position: absolute;
        display: inline-block;
        inset: -3em auto auto 0;
        margin-inline-end: 1rem;
        width: 4em;
        height: 4em;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url('../img/icon/pict_basis-information.svg');

        @include media-breakpoint-down(sm) {
            inset: -2.25em auto auto 1em;
            width: 3em;
            height: 3em;
        }
    }
}

.figure-blockquote {
    margin: 0;

    blockquote {
        margin: 0 0 .5em 0;
    }

    p {
        font-family: var(--font-handwriting);
        @include fluid-type($min_width, $max_width, 32px, 50px);
        line-height: 1.3;
        color: var(--color-primary);
    }

    figcaption {
        margin-top: 1em;
        padding: .25em 1em;
        display: inline-block;
        color: white;
        background-color: var(--color-primary);
    }
}