.table-default {
	overflow-x: auto;

	table {
		width: 100%;
		padding: 0;
		margin: 0;
		font-size: 16px;
		border-collapse: collapse;
	}

	tr {
		// display: block;
		width: 100%;
		@include effect();
	}

	td,
	th {
		width: 100%;
		text-align: left;
		min-width: 100px;
		padding: 1rem;

		&:first-child {
			padding-inline-start: 0.5rem;
		}

		@include media-breakpoint-down(sm) {
			padding: .5rem;
		}
	}

	thead {
		tr {
			&:first-child {
				th {
					vertical-align: middle;

					&:first-child {
						vertical-align: bottom;
						text-align: inherit;
					}
				}
			}

			&:last-child {
				border-bottom: 2px solid var(--text);

				th {
					&:first-child {
						padding-inline-start: 0.5rem;
					}
				}
			}
		}

		th {
			vertical-align: bottom;
			text-align: right;
		}
	}

	tbody {
		tr {
			border-bottom: 1px solid var(--border);

			&:nth-child(3n) {
				border-bottom: 2px solid var(--border);
			}

			&:hover {
				background-color: var(--color-grey-blue);
			}
		}

		td {
			vertical-align: top;

			&:not(:first-child) {
				text-align: right;
			}
		}
	}

	&__highlight {
		background-color: var(--color-grey-blue);
		font-weight: 600;
	}

	&__title {
		font-size: 24px;
	}

	&__year {
		display: inline-block;
		font-size: 18px;
		color: var(--color-primary);
		line-height: 1.3;

	}

	&__suffix {
		display: inline-block;
		font-size: 14px;
		line-height: 1.3;

	}

	&__meta {
		min-width: 100px !important;
		text-align: right !important;
	}

	&[data-table="sdg17"] {
		thead {
			tr {
				&:first-child {
					th {
						vertical-align: bottom;
					}
				}
			}
		}

		td,
		th {
			min-width: 150px;
		}

		tbody {
			tr {
				&:nth-child(3n) {
					border-bottom: 1px solid var(--border);
				}
			}
		}
	}
}