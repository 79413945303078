:root {
    --title-shadow: 20px;
}

.hero {
    position: relative;
    height: 100vh;
    display: grid;
    place-items: center;
    padding: 20px;
    background-color: var(--bg-dark);
    background-color: white;
}

.hero-image {
    position: absolute;
    inset: 40px 0 0 0;
    overflow: hidden;
    z-index: 0;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center bottom;
    }
}

.hero-title {
    position: relative;
    @include fluid-type($min_width, $max_width, 40px, 120px);
    color: var(--color-primary);
    z-index: 10;
    font-weight: 600;
    line-height: 1;

    &[data-shadow="text"] {
        text-shadow: 0 0 var(--title-shadow) rgba(white, 1),
            0 0 var(--title-shadow) rgba(white, 1),
            0 0 var(--title-shadow) rgba(white, 1),
            0 0 var(--title-shadow) rgba(white, 1),
            0 0 var(--title-shadow) rgba(white, 1),
            0 0 var(--title-shadow) rgba(white, 1),
            0 0 var(--title-shadow) rgba(white, 1),
            0 0 var(--title-shadow) rgba(white, 1),
            0 0 var(--title-shadow) rgba(white, 1),
            0 0 var(--title-shadow) rgba(white, 1);

        @include media-breakpoint-down(sm) {
            --title-shadow: 5px
        }
    }
}

.hero-title-sub {
    display: inline-block;
    margin-block-start: .25em;
    @include fluid-type($min_width, $max_width, 20px, 60px);
    line-height: 1.2;
}

.hero-detail {
    position: relative;
    background-color: var(--bg-dark);
    width: 100%;
    height: 100%;
    max-height: 800px;
    aspect-ratio: 3/2;

    &[data-size="full"] {
        height: 100vh;
        max-height: initial;
        aspect-ratio: initial;

        @include media-breakpoint-down(lg) {
            height: initial;
            aspect-ratio: 5/3;
        }
    }

    &[data-size="sm"] {
        max-height: 480px;
    }

    .image {
        position: absolute;
        inset: 0;
        // overflow: hidden;
        z-index: 0;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }

    &__figcaption {
        display: flex;
        justify-content: space-between;
        padding: 1rem;
        gap: 5rem;

        p {
            display: block;
            max-width: 600px;
            margin: 0;
            font-size: 13px;
            font-weight: 400;
            font-style: italic;
            color: rgba(white, .5);
            z-index: 500;

            &:last-child {
                text-align: right;
            }
        }

        @include media-breakpoint-down(sm) {
            flex-wrap: wrap;
            gap: 1rem;
            p {
                &:last-child {
                    text-align: initial;
                }
            }
        }
    }
}