.teaser-grid {
    $this: &;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;

    @include media-breakpoint-down(md) {
        grid-template-columns: repeat(1, 1fr);
    }

    &__item {
        position: relative;
        grid-column: span 1;
        aspect-ratio: 3/2;

        &--big {
            grid-column: span 2;

            @include media-breakpoint-down(md) {
                grid-column: span 1;
            }
        }

        &:hover {
            #{$this}__image {
                img {
                    scale: 1.1;
                }
            }
        }
    }

    &__image {
        position: absolute;
        inset: 0;
        z-index: 5;
        overflow: hidden;

        &::after {
            content: "";
            position: absolute;
            inset: 0;
            background-image: linear-gradient(0, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 75%);
            z-index: 5;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            @include effect(all, .5s, ease-in-out);
        }
    }

    &__box {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 2rem calc(50px + 3rem) 2rem 2rem;
        height: 100%;
        z-index: 10;

        @include media-breakpoint-down(md) {
            padding: 1rem calc(50px + 2rem) 1rem 1rem;
        }
    }

    &__btn {
        position: absolute;
        inset: auto 2rem 2rem auto;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;
        background-color: var(--btn-primary);
        border-radius: 50vw;
        z-index: 15;
        @include effect();

        @include media-breakpoint-down(md) {
            inset: auto 1rem 1rem auto;
        }

        &::after {
            content: "";
            position: relative;
            width: 20px;
            height: 20px;
            background-color: white;
            mask-image: url('../img/icon/icon-arrow-right.svg');
            mask-repeat: no-repeat;
            mask-position: center;
            mask-size: 20px;
        }

        &:hover {
            background-color: var(--btn-primary-hover);
        }
    }


    &__title {
        @include fluid-type($min_width, $max_width, 24px, 32px);
        margin-block-end: .5rem;
        --title: white;
    }

    &__text {
        --text: white;
        font-size: 16px;
    }

}