// .logo, .logo-bg {
//     position: fixed;
//     inset: 50px auto auto 40px;
//     display: inline-block;
//     z-index: 999;
//     @include effect(all, .5s, ease-in-out);
//     transition-delay: .25s;

//     @include media-breakpoint-down(sm) {
//         inset: 30px auto auto 20px;
//     }    

//     &.hide {
//         transform: translateY(-20px);
//         opacity: 0;
//         visibility: hidden;
//     }

//     img {
//         width: 240px;
//         height: auto;

//         @include media-breakpoint-down(sm) {
//             width: 200px;
//         }
//     }
// }

// .logo-bg {
//     padding: 1.5rem 1.5rem 1.0rem 1.5rem;
//     background-color: #ffffff;
//     border-radius: 0 0 7px 7px;
//     inset: 0 auto auto 40px;
// }

.logo {
    position: fixed;
    inset: 0 auto auto 40px;
    display: flex;
    align-items: center;
    @include effect(all, .5s, ease-in-out);
    // transition-delay: .25s;
    padding: 20px;
    background-color: white;
    border-radius: 0 0 10px 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
    z-index: 999;

    @include media-breakpoint-down(sm) {
        inset: 0 0 auto 0;
        border-radius: 0;
        min-height: 80px;
    }

    &.hide {
        transform: translateY(-20px);
        opacity: 0;
        visibility: hidden;
    }

    a {
        display: flex;
    }

    img {
        width: 260px;
        height: auto;

        @include media-breakpoint-down(sm) {
            width: 220px;
        }
    }
}